import appConfig from "./appConfig";

const apiConfig = {
  publicURL: appConfig.host,
  baseURL: (
    appConfig.host +
    "/" +
    appConfig.prefix +
    "/" +
    appConfig.version +
    "/"
  ).replace(/([^:]\/)\/+/g, "$1"),

  // * ----- Auth Apis ----- *
  login: "login",

  // * ----- Change Password ----- *
  changePassword: "user-master/change-password",

  // * ----- User Managment  ----- *
  userMaster: "user-master",
  editUserMaster: "user-master/:id",
  toggleActiveDeactiveuser: "user-master/toggle-user-active/:id",

  // * ----- Customer  ----- *
  customerDetails: "customer-details",
  toggleActiveDeactivecustomer: "customer-details/toggle-customer-active/:id",
  deleteCustomer: "customer-details/:id",
  generateLogin: "customer-details/generate-login-id/:id",

  // * ----- Categories Managment  ----- *
  catgory: "category",
  editCategory: "category/:id",

  // * ----- Sub Categories Managment  ----- *
  subCatgory: "sub-category",
  editSubCatgory: "sub-category/:id",
  subCategoryAttributesId: "sub-category/attributes/:id",

  // * ----- Product Managment  ----- *
  products: "products",
  editProducts: "products/:id",
  productsBulk: "products/bulk",
  toggleActiveDeactiveProduct: "products/toggle-product-active/:id",
  // * ----- Catalog Managment  ----- *
  catalogMaster: "catalog-master",
  editCatalogMaster: "catalog-master/:id",
  toggleActiveDeactiveCatalogMaster: "catalog-master/toggle-catalog-active/:id",

  // * ----- Attributes  ----- *
  attributes: "attributes",
  editAttributes: "attributes/:id",

  // * ----- Options  ----- *
  options: "options",
  editOptions: "options/:id",

  // * ----- List APis  ----- *
  listCategory: "list/category",
  listOptions: "list/options",
  listSubCategory: "list/sub-category",
  listAttribute: "list/attributes",
  listCustomers: "list/customers",
  listProducts: "list/products",

  // * ----- User Permission  ----- *
  getuserPermission: "user-permission/:id",
  getUserNotPermission: "user-permission/:id/not",
  addUserPermission: "user-permission",
  editUserPermission: "user-permission/:id/toggle",
  deleteUserPermission: "user-permission/:id",

  // * ----- Customer Registration  ----- *
  customerRegistration: "registration",

  // * ----- Admin Email Subscribed  ----- *
  adminEmailSubscribed: "email-subscribed",
  deleteAdminEmailSubscribed: "email-subscribed/:id",

  // * ----- Enquiry Now  ----- *
  enquiryNow: "enquiry-now",
  toggleIsReadEnquiry: "enquiry-now/toggle-is-read/:id",
  deleteEnquiryNow: "enquiry-now/:id",

  //  * -------------------------------------- Customer APIS ---------------------------------------*
  // * ----- Change Password ----- *
  changePasswordCustomer: "customer-details/change-password",

  // * ----- Change Profile ----- *
  customerDetailsProfile: "customer-details/profile",

  // * ----- Add To Quote ----- *
  addToQuote: "add-to-quote",
  addToQuoteCount: "add-to-quote/count",
  editToQuote: "add-to-quote/:id",

  // * ----- Quotation ----- *
  quotation: "quotation",
  findQuotation: "quotation/:id",
  toggleQuotationStatus: "quotation/status/:id",

  // * ----- Style Master ----- *
  styleMaster: "style-master/sub-category/:id",
  findStyleMaster: "style-master/:id",

  // * ----- Wish List ----- *
  wishlist: "wishList",
  editWishlist: "wishList/:id",
  // findStyleMaster: "style-master/:id",

  // * ----- Customer Catalog  ----- *
  customerCatalog: "catalog",
  findCatalog: "catalog/:id",

  // * ----- Other Details  ----- *
  otherDetaills: "other-details",
  productsImages: "products/product-images/:stock_id",

  // * -------- Dashboard --------- *
  dashboardTotal: "dashboard/total",

  // * -------- Home Page ( Landing Page ) --------- *
  publicLandingPage: "page",
  publicLandingPageSection: "page/:section",
  publicLandingEmailSubscribed: "email-subscribed",

  // * -------- Admin Landing Page --------- *
  homePageSetupSection: "home-page-setup",
  homePageSectionWiseAdmin: "/home-page-setup/:section",
  homePageAboutUs: "home-page-setup/about-us",
  homePageBannerSection: "home-page-setup/banner",
  homePageBottomSection: "home-page-setup/bottom-section",
  homePageOurCategorySection: "home-page-setup/our-category",
  homePageSpecialOffers: "home-page-setup/special-offers",
  homePageSliderSection: "home-page-setup/slider",

  // * -------- Forgot Pasword Page --------- *
  forgotPassword: "forgot-password",

  // * -------- Verify Url --------- *
  verifyUrl: "verify-url",

  // * -------- Reset Password --------- *
  resetPassword: "reset-password",

  // * -------- PDF download --------- *
  catalogPdfDownload: "catalog/:id/pdf-download",
};

export default apiConfig;
