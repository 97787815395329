import React, { useState } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toaster } from "../../services/helper";
import Textinput from "../../Components/Common/Textinput";
import ReactButton from "../../Components/Common/ReactButton";
import Validators from "../../Components/validations/Validator";
import { API, CustomerAPI, HELPER } from "../../services";
import { apiConfig } from "../../configs";
import { useSelector } from "react-redux";

const ChangePassword = ({ modal, toggle, setModal }) => {
  const [isLoader, setIsLoader] = useState(false);
  const auth = useSelector((state) => state?.auth?.user);
  //  -------------formState --------------
  const [formState, setFormState] = useState({
    oldPassword: "",
    newPassword: "",
  });
  //  -------------Validation --------------
  const rules = {
    oldPassword: "required",
    newPassword: "required",
  };

  //  -------------handle Change Password --------------
  const handleSubmit = (data) => {
    if (auth?.user_type === "admin" || auth?.user_type === "user") {
      setIsLoader(true);
      API.put(apiConfig.changePassword, data)
        .then((res) => {
          toaster.success("Change Password successfully");
          setModal(false);
        })
        .catch(HELPER.showErrorToaster)
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      setIsLoader(true);
      CustomerAPI.put(apiConfig.changePasswordCustomer, data)
        .then((res) => {
          toaster.success("Change Password successfully");
          setModal(false);
        })
        .catch(HELPER.showErrorToaster)
        .finally(() => {
          setIsLoader(false);
        });
    }
  };
  // -----------------onChange--------------------
  const onChange = ({ target: { value, name } }) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    // -------------------Change Password Modal---------------------
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Change Password
        </ModalHeader>
        <ModalBody>
          <Validators formData={formState} rules={rules}>
            {({ onSubmit, errors }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(handleSubmit);
                  }}
                  action="#"
                >
                  <Textinput
                    label={"Old Password"}
                    value={formState.oldPassword}
                    name="oldPassword"
                    placeholder="Enter Old Password"
                    error={errors?.oldPassword}
                    type="password"
                    onChange={onChange}
                    isRequredFiled={true}
                  />
                  <Textinput
                    label={"New Password"}
                    value={formState.newPassword}
                    name="newPassword"
                    placeholder="Enter New Password"
                    error={errors?.newPassword}
                    type="password"
                    onChange={onChange}
                    isRequredFiled={true}
                  />
                  <div className="mt-4">
                    <ReactButton
                      loader={isLoader}
                      color="success"
                      className="btn btn-success w-100"
                      type="submit"
                    >
                      Submit
                    </ReactButton>
                  </div>
                </Form>
              );
            }}
          </Validators>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePassword;
