const appConfig = {
  host: process.env.REACT_APP_API_HOST,
  prefix: process.env.REACT_APP_API_PREFIX,
  version: process.env.REACT_APP_API_VERSION,
  publicSitekey: process.env.REACT_APP_PUBLIC_SITE_KEY,
  nodeEnv: process.env.NODE_ENV,
  dateDisplayFormat: "DD/MM/YYYY",
  dateFormat: "DD-MM-YYYY",
  dateDisplayEditFormat: "YYYY-MM-DD",
  dateAndTimeDisplayFormat: "DD/MM/YYYY HH:mm A",
  dateAndTimeFormat: "DD-MM-YYYY HH:mm A",
  displayDateFormat: "DD-MM-YYYY HH:mm A",
  displayDateAndTimeOnly: "DD-MM-YYYY hh:mm A",
  dateAndTimeDisplay: "YYYY/MM/DD HH:mm",
  timeDisplay: "HH:mm",
  displayDay: "ddd",
  defaultPerPage: 9999999,
  localStorage: {
    token: "attendance-bearer-token",
  },

  persistKey: {
    authReducer: "auth",
  },
  default_pagination_state: {
    page: 0,
    rowsPerPage: 12,
  },
};

export default appConfig;
