import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";

//import Scss
import "./assets/scss/themes.scss";
import "./assets/scss/common/common.scss";

//import Route
import Route from "./Routes";
import { appConfig } from "./configs";

function App() {
  useEffect(() => {
    if (appConfig.nodeEnv === "production") {
      const handleContextMenu = (event) => {
        event.preventDefault();
      };

      const handleKeyDown = (event) => {
        if (
          (event.ctrlKey && event.shiftKey && event.key === "I") || // Ctrl+Shift+I
          (event.ctrlKey && event.shiftKey && event.key === "C") || // Ctrl+Shift+C
          (event.ctrlKey && event.shiftKey && event.key === "J") || // Ctrl+Shift+J
          (event.ctrlKey && event.key === "U") || // Ctrl+U
          event.key === "F12" // F12
        ) {
          event.preventDefault();
        }
      };

      document.addEventListener("contextmenu", handleContextMenu);
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  return (
    <React.Fragment>
      <Toaster position="top-center" reverseOrder={false} />
      <Route />
    </React.Fragment>
  );
}

export default App;
