//  ------------------ User Switch ( Active Deactive ) -------------------

export const isActiveDeactive = [
  { label: "Active", value: true },
  { label: "Deactive", value: false },
];
export const isReadEnquiry = [
  { label: "True", value: true },
  { label: "False", value: false },
];
export const quotationStatus = [
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
];
export const descriptionOfBussinessOptions = [
  { label: "Retailer", value: "retailer" },
  { label: "Wholesaler", value: "wholesaler" },
  { label: "Dealer", value: "dealer" },
  { label: "Financier", value: "financier" },
  { label: "Other", value: "other" },
];
