import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle, Form } from "reactstrap";

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import customerLogo from "../assets/images/customer-logo.png";

//import Components
import SearchOption from "../Components/Common/SearchOption";
import ProfileDropdown from "../Components/Common/ProfileDropdown";

import { changeSidebarVisibility } from "../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ROUTES } from "../configs";
import { HELPER } from "../services";

const Header = ({ headerClass }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  );
  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData);

  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === "show" &&
      (document.documentElement.getAttribute("data-layout") === "vertical" ||
        document.documentElement.getAttribute("data-layout") === "semibox")
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };
  const navigate = useNavigate();
  const liveProductsRef = useRef();

  // State to track active tab
  const [activeTab, setActiveTab] = useState("liveProducts");

  const handleClickLiveProducts = () => {
    setActiveTab("liveProducts");
    navigate(ROUTES.CUSTOMER_CATALOG);
  };

  const handleClickPdfCatalogs = () => {
    setActiveTab("pdfCatalogs");
    navigate(ROUTES.CUSTOMER_PDF_CATALOG);
  };

  const handleClickAddToQuote = () => {
    setActiveTab("addToQuote");
    navigate(ROUTES.ADD_TO_QUOTE);
  };

  const handleClickQuotation = () => {
    setActiveTab("quotation");
    navigate(ROUTES.QUOTATION);
  };

  useEffect(() => {
    if (liveProductsRef.current) {
      liveProductsRef.current.focus();
    }
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div
          className={`
          ${auth?.user?.name !== "Admin" ? "" : "layout-width"}
`}
        >
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo pe-0">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="35" />
                  </span>
                  <span className="logo-lg">
                    <img src={customerLogo} alt="" height="35" />
                    {/* <span className="catalog-name-logo">VENEZIA</span> */}
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="35" />
                  </span>
                  <span className="logo-lg">
                    <img src={customerLogo} alt="" height="35" />
                    {/* <span>VENEZIA</span> */}
                  </span>
                </Link>
              </div>
              {!HELPER.isEmpty(auth?.user?.name) ? (
                <button
                  onClick={toogleMenuBtn}
                  type="button"
                  className={`btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger ${
                    auth?.user?.name !== "Admin" && ""
                  }`}
                  id="topnav-hamburger-icon"
                >
                  <span className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              ) : (
                <>
                  <div
                    className={` topnav-hamburger `}
                    id="topnav-hamburger-icon"
                  >
                    <span className="hamburger-icon"></span>
                  </div>
                </>
              )}

              {/* {auth?.user?.name === "Admin" &&  */}
              <SearchOption />
              {/* } */}
              {HELPER.isEmpty(auth?.user?.name) && (
                <div className="main-header-links-wrapper">
                  <div className="wraper-content">
                    <div
                      className={`d-flex align-items-center heading-text-div ${
                        activeTab === "liveProducts" ? "active-tab" : ""
                      }`}
                      onClick={handleClickLiveProducts}
                    >
                      <i className="ri-product-hunt-line nav-icons" />
                      <a
                        ref={liveProductsRef}
                        className="dropdown-item notify-item"
                        tabIndex="0"
                      >
                        <span>Live Products</span>
                      </a>
                    </div>
                    <div
                      className={`d-flex align-items-center ms-3 heading-text-div ${
                        activeTab === "pdfCatalogs" ? "active-tab" : ""
                      }`}
                      onClick={handleClickPdfCatalogs}
                    >
                      <i className="ri-file-pdf-line nav-icons" />
                      <a className="dropdown-item notify-item" tabIndex="0">
                        <span>PDF Catalogs</span>
                      </a>
                    </div>
                    <div
                      className={`d-flex align-items-center ms-3 ${
                        activeTab === "addToQuote" ? "active-tab" : ""
                      }`}
                      onClick={handleClickAddToQuote}
                    >
                      <i className="ri-book-open-line nav-icons" />
                      <a className="dropdown-item notify-item" tabIndex="0">
                        <span>Add To Quote</span>
                      </a>
                    </div>
                    <div
                      className={`d-flex align-items-center ms-3 ${
                        activeTab === "quotation" ? "active-tab" : ""
                      }`}
                      onClick={handleClickQuotation}
                    >
                      <i className="ri-file-list-2-line nav-icons" />
                      <a className="dropdown-item notify-item" tabIndex="0">
                        <span>Quotation</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item"
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
