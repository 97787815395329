import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <div className="fs-16">
              {new Date().getFullYear()} © VENEZIA Jewels.
            </div>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
