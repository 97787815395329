import _, { isArray, reject } from "lodash";
import { toast } from "react-hot-toast";
import { appConfig } from "../configs";
import moment from "moment";
import avatarImg from "../assets/images/users/noimg.jpg";

export const isEmpty = (value) => {
  if (value == null || value === "null") {
    return true;
  }
  if (typeof value == "object") {
    return Object.keys(value).length === 0;
  }
  return (
    (isArray(value) && value.length === 0) ||
    value === undefined ||
    value === "undefined" ||
    value == null ||
    value === ""
  );
};

export const toaster = {
  error: (message, config = {}) => {
    toast.error(message);
  },
  success: (message, config = {}) => {
    toast.success(message);
  },
};

export const isAuth = () => {
  return (
    false == isEmpty(window.localStorage.getItem(appConfig.localStorage.token))
  );
};

export const getTotalWeeksDates = (month) => {
  let _month = moment(month);
  let stateDate = moment(_month).startOf("month");
  let endDate = moment(_month).endOf("month");

  let lastDateOfMonth = moment(_month).endOf("month");

  let weeks = [];

  for (let i = 0; i < 10; i++) {
    if (i != 0) {
      stateDate = stateDate.add(1, "weeks").startOf("isoWeek");
    }

    let isLastDateOfMonth = moment(
      stateDate.clone().endOf("isoWeek")
    ).isSameOrAfter(lastDateOfMonth);

    weeks.push({
      start_date: stateDate.format("YYYY-MM-DD"),
      end_date: isLastDateOfMonth
        ? endDate.clone().format("YYYY-MM-DD")
        : stateDate.clone().endOf("isoWeek").format("YYYY-MM-DD"),
    });

    if (isLastDateOfMonth) {
      break;
    }
  }

  return weeks;
};
export const getImageUrl = (url) => {
  if (isEmpty(url)) {
    return avatarImg;
  }
  const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
  if (isAbsoluteUrl) {
    return url;
  } else {
    return `${appConfig.host}${url}`.replace(/([^:]\/)\/+/g, "$1");
  }
};

export const imgObjToBase64 = (imgObj) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(imgObj);
  });
};

export const showErrorToaster = (err) => {
  if (err?.errors?.message?.code) {
    toaster.error(err?.errors?.message?.error);
  } else if (err?.errors?.message) {
    toaster.error(err?.errors?.message);
  } else if (err?.errors) {
    if (Array.isArray(err?.errors)) {
      err?.errors.forEach((error) => {
        toaster.error(error);
      });
    } else if (typeof err.errors === "string") {
      toaster.error(err?.errors);
    } else {
      Object.values(err?.errors).forEach((error) => {
        toaster.error(error);
      });
    }
  } else {
    console.error(err);
  }
};

// export const showErrorToaster = (err) => {
//   if (err?.errors?.message) {
//     toaster.error(err?.errors?.message);
//   } else if (err?.errors) {
//     Object.values(err?.errors).map((error) => {
//       toaster.error(error);
//     });
//   } else {
//     console.error(err);
//   }
// };

export const GetFormattedAmount = (amount, decimal) => {
  let formattedAmount = "";
  if (!Number.isNaN(amount)) {
    amount = Number(amount).toFixed(decimal);

    formattedAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal,
    }).format(amount);
  }
  return formattedAmount;
};
