import React from "react";
import { Spinner } from "reactstrap";
import LoaderGif from "../../assets/images/loading.gif";
const PageLoader = () => {
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center page-loader-height">
        {/* <Spinner color="primary"> Loading... </Spinner> */}
        <img src={LoaderGif} alt="Logo" className="loader-git-img" />
      </div>
    </React.Fragment>
  );
};

export default PageLoader;
